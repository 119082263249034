import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/HC.png";
import chatify from "../../Assets/Projects/banking.jpeg";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/projeto-cnn.jpeg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Banking"
              description="A banking transaction project involves developing a secure and efficient system for managing the financial operations of a banking institution. The aim is to provide users with a reliable platform to carry out transactions such as deposits, withdrawals, transfers and payments."
              ghLink="https://github.com/RamonSouzaDev/banking"
              demoLink="https://ramon-developer.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Mega Sena - Loterias"
              description="A WordPress plugin to display Caixa lottery results."
              ghLink="https://github.com/RamonSouzaDev/teste-fullstack"
              demoLink="http://ramon-developer.com/cnn-loterias/9-2/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="To Do List"
              description="To-Do List application that allows the user to add, list, mark how to complete and delete tasks."
              ghLink="https://github.com/RamonSouzaDev/To-Do-List"
              demoLink="https://github.com/RamonSouzaDev/To-Do-List"              
            />
          </Col>

         
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
