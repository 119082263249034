import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Ramon Mendes </span>
            from <span className="purple"> Barueri, Brazil.</span>
            <br />
            PHP: Expert – PHP is my core programming language, and I have in-depth knowledge of frameworks like Laravel, Symfony, and CodeIgniter.
            <br />
            I have extensive experience in building scalable APIs, particularly in PHP using frameworks like Laravel and Symfony.
            <br />
            <br />
             For example, I developed a RESTful API for a logistics platform that handled real-time tracking of shipments. To ensure scalability, I implemented caching strategies using Redis and optimized database queries to reduce load times. I also used JWT (JSON Web Tokens) for secure user authentication and authorization. The API was designed with versioning in mind to support future updates without disrupting existing clients. Moreover, I followed RESTful principles closely to ensure that the API was intuitive and easy to integrate with by other developers.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Ramon Mendes</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
